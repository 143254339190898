<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
    ></list-template>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="600px" @close="dialogVisible = false">
      <el-form v-if="title === '查看'" label-position="left" label-width="120px" size="small">
        <el-form-item label="校区">{{ form.school }}</el-form-item>
        <el-form-item label="类型">{{ form.set_type }}</el-form-item>
        <el-form-item label="时间">{{ form.warning_minite }}</el-form-item>
        <el-form-item label="推送方式">{{ form.push_mode_text }}</el-form-item>
        <el-form-item label="推送人">{{ form.push_man }}</el-form-item>
        <el-form-item label="创建时间">{{ form.created_at }}</el-form-item>
        <el-form-item label="创建人">{{ form.creator }}</el-form-item>
        <el-form-item label="停用时间">{{ form.stop_at }}</el-form-item>
        <el-form-item label="停用人">{{ form.stop_creator }}</el-form-item>
        <div style="width: 100%;text-align: center;margin-top: 36px">
          <el-button style="width: 120px" type="primary" @click="dialogVisible = false">关闭</el-button>
        </div>
      </el-form>
      <div v-else>
        <div style="width: 100%;text-align: center">
          <span>停用后，系统将停止按照此规则推送考勤异常消息</span>
          <div style="margin-top: 78px">
            <el-button style="width: 120px" type="primary" @click="onConfirm">仍然停用</el-button>
            <el-button style="width: 120px" @click="dialogVisible = false">点错了</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import TimeLine from "@/components/common/TimeLine";
import {pageLoadingMixin} from "@/utils/mixins";

export default {
	_config:{"route":{"path":"index","meta":{"title":"配置"},default:true}},
  name: 'Late',
  components: { TimeLine },
  mixins: [pageLoadingMixin],
  data() {
    return {
      loading: true,
      tableConfig: [{
        prop: 'school',
        label: '所属校区'
      }, {
        prop: 'set_type',
        label: '类型 '
      }, {
        prop: 'warning_minite',
        label: '预警时间（分钟）'
      }, {
        prop: 'push_mode',
        label: '推送方式'
      }, {
        prop: 'push_man',
        label: '推送人'
      }, {
        label: '操作',
        handle: true,
        width: 120,
        render: row => {
          return !Number(row?.status) ? ['停用'] : ['查看']
        }
      }],
      search: null,
      total: 0,
      tableData: [],
      dialogVisible: false,
      form: {},
      title: ''
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.$_axios.get('site/school').then(res => {
      this.searchConfig[0].options = res.data.data
    })
  },
  activated() {
    this.getData()
  },
  methods: {
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/attendance/attendance-warning/list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    onAdd() {
      this.$router.push('./add')
    },
    onSearch(val) {
      this.search = val
      this.getData(val)
    },
    tableHandle(row) {
      this.form = row
      if (!!row?.status) {
        this.title = '查看'
        this.$_axios2.get('api/attendance/attendance-warning/get-minite', {params: {id: row.id}}).then(res => {
          this.form = res.data.data
        })
        this.dialogVisible = true
      } else {
        this.title = '停用提醒'
        this.dialogVisible = true
      }
    },
    onConfirm() {
      this.$_axios2.get('api/attendance/attendance-warning/block-up', {params: {id: this.form.id}}).then(res => {
        if (res.data.status !== 0) return
        this.getData()
        this.dialogVisible = false
        this.$message.success('停用成功')
      })
    }
  }
}
</script>



<style lang="scss" scoped>
::v-deep .el-select{
  width: 250rem;
  .el-input{
    width: 100% !important;
  }
}
::v-deep .el-input{
  width: 100rem !important;
}
</style>
